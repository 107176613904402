import request from '@/utils/request.js';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

function getSiteGroup(page = 1, size = 1000, PID = '') {
  return request({
    url: `${server}/dataCenter/qcp/sites/list?page=${page}&size=${size}&PID=${PID}`,
    // url: `${server}/${base_url.core}/sites/list?page=${page}&size=${size}&PID=A10`,
    method: 'GET',
  });
}

function addSiteInformation(data) {
  return request({
    url: `${server}/dataCenter/qcp/sites/add`,
    method: 'POST',
    data,
  });
}
function getSiteDetail(id = '') {
  return request({
    url: `${server}/${base_url.core}/sites/siteinfo?siteID=${id}`,
    method: 'GET',
  });
}

function siteListMisions() {
  return request({
    url: `${server}/dataCenter/qcp/sites/missions`,
    method: 'GET',
  });
}

function updateSiteInformation(data) {
  return request({
    url: `${server}/${base_url.core}/sites/update`,
    method: 'PUT',
    data,
  });
}

function deleteSiteByStid(stid = '') {
  return request({
    url: `${server}/dataCenter/qcp/sites/delete?siteID=${stid}`,
    method: 'DELETE',
  });
}



export { getSiteGroup, getSiteDetail, addSiteInformation, updateSiteInformation, deleteSiteByStid, siteListMisions };
